<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card flat class="" max-width="100%">
      <v-card class="mx-auto">
        <v-toolbar color="cyan darken-1" dark>
          <v-toolbar-title> Acerca de nosotros </v-toolbar-title>
        </v-toolbar>
        <v-row class="mt-5 mb-5">
          <v-col cols="12" class="" md="1" sm="0" xs="0"></v-col>
          <v-col cols="12" class="" md="4" sm="10" xs="10">
            <v-img
              src="../../src/assets/terminal_lite-1.jpg"
              class=""
              width="500"
              contain
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="7">
            <v-card class="elevation-2 mx-10">
              <p class="mx-5 pb-5 pt-5 text--secondary">
                En Sipymex sabemos que su tiempo es lo más valioso, estamos
                enfocados en ofrecer soluciones integrales que contribuyen a una
                mayor eficiencia de gestión en su negocio, con un trato
                personalizado y rápida respuesta a sus requerimientos.
              </p>
            </v-card>
            <div class="mx-10">
              <v-timeline :dense="$vuetify.breakpoint.smAndDown">
                <v-timeline-item
                  v-for="description in descriptions"
                  :key="description"
                >
                  <span slot="opposite"></span>
                  <v-card class="elevation-2">
                    <v-card-text>
                      {{ description.text }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </v-container>
</template>
<script>
//import axios from "axios";
export default {
  data: () => ({
    descriptions_us: [
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Menor inversión inicial.",
      },
      {
        icon: "mdi-checkbox-marked-circle",
        text: "Documentos mensuales ilimitados.",
      },
    ],
    descriptions: [
      {
        item: "mdi-checkbox-marked-circle",
        text: "Menor inversión inicial.",
      },
      {
        item: "mdi-checkbox-marked-circle",
        text: "Documentos mensuales ilimitados.",
      },
    ],
  }),
};
</script>